import React from 'react';
import { Container, Row, Col, Image } from 'react-bootstrap';
import logo from "../assets/logo.png";
const ComingSoon = () => {
  return (
    <Container fluid className="d-flex vh-100 justify-content-center align-items-center bg-light">
      <Row className="text-center">
        <Col>
          <Image src={logo} alt="Logo" width={250} height={80} className="mb-4" />
          <h1 className="fw-bold">Coming Soon</h1>
          <p className="text-muted">We are working hard to bring you something amazing. Stay tuned for updates!</p>
        </Col>
      </Row>
    </Container>
  );
};

export default ComingSoon;
