import React from 'react';
import ReactDOM from 'react-dom/client';
import App from './App';
import 'bootstrap/dist/css/bootstrap.min.css';
import "./global.css"
import { BrowserRouter } from 'react-router-dom';
import Footer from 'components/Shared/Footer';
import NavigationBar from 'components/Shared/NavigationBar';
import ScrollToTop from 'components/Shared/ScrollToTop';
import { ToastContainer } from 'react-toastify';
import "react-toastify/dist/ReactToastify.css";
const root = ReactDOM.createRoot(document.getElementById('root'));
root.render(
  <React.StrictMode>
      <ToastContainer />
    <BrowserRouter>
      <ScrollToTop />
      {/* <NavigationBar /> */}
      <App />
      {/* <Footer /> */}
    </BrowserRouter>
  </React.StrictMode>
);

